var $ = jQuery.noConflict();

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin')) method = 'wp-admin';

    var initializeBlock = function ($block) {
        // Place for your code

        $('.block-tabbed-content__block-buttons .single').click(function (e) {
            e.preventDefault();
            var topParent = $(this).parents('.block-tabbed-content');
            var toggledElem = $(this).attr('data-target');

            toggleTabbedElements(toggledElem, topParent);
        });

        $('.block-tabbed-content__select .tab-select').on(
            'change',
            function () {
                var topParent = $(this).parents('.block-tabbed-content');
                var toggledElem = $(this).val();

                toggleTabbedElements(toggledElem, topParent);
            }
        );

        function toggleTabbedElements(toggledElem, topParent) {
            topParent
                .find('.block-tabbed-content__block-buttons .single.active')
                .removeClass('active');
            topParent
                .find(
                    '.block-tabbed-content__block-buttons .single[data-target=' +
                        toggledElem +
                        ']'
                )
                .addClass('active');
            topParent
                .find(
                    '.block-tabbed-content__tabbed-elements .single-tab.active'
                )
                .removeClass('active');
            topParent.find('.tab-select').val(toggledElem);
            topParent.find(toggledElem).addClass('active');
        }

        $('.block-tabbed-content__tabbed-elements .side .swiper').each(
            function () {
                if ($(this).find('.swiper-slide').length > 1) {
                    var blockId = $(this).closest('.single-tab').attr('id');

                    var swiperInit = new Swiper(this, {
                        slidesPerView: 1,
                        autoHeight: false,
                        spaceBetween: 0,
                        loop: false,
                        slideClass: 'swiper-slide',
                        navigation: {
                            prevEl: '#' + blockId + ' .btn-circle--prev',
                            nextEl: '#' + blockId + ' .btn-circle--next',
                        },
                    });
                }
            }
        );

        $('.block-tabbed-content__tabbed-elements .video-wrapper').click(
            function () {
                $(this).find('.modal').toggleClass('active');
                $(this)
                    .closest('.block-tabbed-content__tabbed-elements')
                    .toggleClass('modal-opened');
                $('body').toggleClass('modal-opened');
            }
        );

        $('.modal').click(function (e) {
            e.stopImmediatePropagation();
            $(this).toggleClass('active');
            $(this)
                .closest('.block-tabbed-content__tabbed-elements')
                .toggleClass('modal-opened');
            $('body').toggleClass('modal-opened');
        });
        $('.modal-content').click(function (e) {
            e.stopImmediatePropagation();
        });
    };

    window.onload = (function () {
        initializeBlock($(this));
    })();

    if (window.acf) {
        window.acf.addAction(
            'render_block_preview/type=tabbed-content',
            initializeBlock
        );
    }
});
